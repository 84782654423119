import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Decoration2 from '../components/Decoration/Decoration-2';

import SvgLinkArrow from '../assets/images/vectors/icons/link-arrow.inline.svg';

import '../assets/styles/pages/learn-and-live-page.scss';
import StartJourneyButton from '../components/StartJourneyButton';

const LivePage = ({ data }) => {
  const prismicData = data.prismic.allLive_pages.edges[0].node;
  return (
    <Layout>
      <SEO title="Live" />
      <div className="learn-and-live-page">
        <FirstSection data={data} prismicData={prismicData} />
        <SecondSection prismicData={prismicData} />
        <ThirdSection data={data} prismicData={prismicData} />
        <FourthSection prismicData={prismicData} />
      </div>
    </Layout>
  );
};

const FirstSection = ({
  data: { imageFirstSection },
  prismicData: { first_section_title, first_section_subtitle },
}) => (
  <section className="first-section">
    <section className="common-first-section">
      <div className="container">
        <div className="cfs__wrapper">
          <div className="cfs__l">
            <div>
              <h1 className="c-h1 cfs__l__title">{RichText.render(first_section_title)}</h1>
              <p>{RichText.asText(first_section_subtitle)}</p>
              <Link to="/pricing/" className="c-btn">
                Start your journey
              </Link>
            </div>
          </div>
          <div className="cfs__r">
            <Decoration2 />
            <Img fluid={imageFirstSection.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  </section>
);

const SecondSection = ({ prismicData: { second_section_content } }) => (
  <section className="second-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 col-lg-8 col-xl-6 mx-auto">
          {RichText.render(second_section_content)}
        </div>
      </div>
    </div>
  </section>
);

const ThirdSection = ({
  data: {
    imageThirdSectionQuest,
    imageThirdSectionTriva,
    imageThirdSectioniRide,
    imageThirdSectionLatentPay,
    imageThirdSectionQuantum,
  },
  prismicData: {
    quest_title,
    quest_description,
    triva_title,
    triva_description,
    iride_title,
    iride_description,
    latent_pay_title,
    latent_pay_description,
    quantum_title,
    quantum_description,
  },
}) => (
  <section className="c-section-mt third-section">
    <div className="container">
      <div className="row third-section__row">
        <div className="col-md-7 third-section__row__image">
          <Img fluid={imageThirdSectionQuest.childImageSharp.fluid} />
        </div>
        <div className="col-md-5 third-section__row__text">
          <div className="c-h1">{RichText.asText(quest_title)}</div>
          <p>{RichText.asText(quest_description)}</p>
          <Link to="/quest/" className="c-link--arrow">
            <span>Discover more</span>
            <SvgLinkArrow />
          </Link>
        </div>
      </div>

      {/*<div className="row third-section__row--reverse">*/}
      {/*  <div className="col-md-5 third-section__row--reverse__text">*/}
      {/*    <div className="c-h1">{RichText.asText(triva_title)}</div>*/}
      {/*    <p>{RichText.asText(triva_description)}</p>*/}
      {/*    <Link to="/triva/" className="c-link--arrow">*/}
      {/*      <span>View features</span>*/}
      {/*      <SvgLinkArrow />*/}
      {/*    </Link>*/}
      {/*  </div>*/}

      {/*  <div className="col-md-7 third-section__row--reverse__image">*/}
      {/*    <Img fluid={imageThirdSectionTriva.childImageSharp.fluid} />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="row third-section__row">*/}
      {/*  <div className="col-md-7 third-section__row__image">*/}
      {/*    <Img fluid={imageThirdSectioniRide.childImageSharp.fluid} />*/}
      {/*  </div>*/}
      {/*  <div className="col-md-5 third-section__row__text">*/}
      {/*    <div className="c-h1">{RichText.asText(iride_title)}</div>*/}
      {/*    <p>{RichText.asText(iride_description)}</p>*/}
      {/*    <Link to="/iride/" className="c-link--arrow">*/}
      {/*      <span>Learn more</span>*/}
      {/*      <SvgLinkArrow />*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="row third-section__row--reverse">*/}
      {/*  <div className="col-md-5 third-section__row--reverse__text">*/}
      {/*    <div className="c-h1">{RichText.asText(latent_pay_title)}</div>*/}
      {/*    <p>{RichText.asText(latent_pay_description)}</p>*/}
      {/*    <Link to="/latent-pay/" className="c-link--arrow">*/}
      {/*      <span>Learn more</span>*/}
      {/*      <SvgLinkArrow />*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  <div className="col-md-7 third-section__row--reverse__image">*/}
      {/*    <Img fluid={imageThirdSectionLatentPay.childImageSharp.fluid} />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="row third-section__row">
        <div className="col-md-7 third-section__row__image">
          <Img fluid={imageThirdSectionQuantum.childImageSharp.fluid} />
        </div>
        <div className="col-md-5 third-section__row__text">
          <div className="c-h1">{RichText.asText(quantum_title)}</div>
          <p>{RichText.asText(quantum_description)}</p>
          <Link to="/quantum/" className="c-link--arrow">
            <span>Learn more</span>
            <SvgLinkArrow />
          </Link>
        </div>
      </div>
    </div>
  </section>
);

const FourthSection = ({ prismicData: { fourth_section_title } }) => (
  <section className="c-section-mt fourth-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="fourth-section__bg">
            <div className="fourth-section__wrapper">
              <div className="c-h2 text-white ">{RichText.asText(fourth_section_title)}</div>
              <StartJourneyButton text="Sign up now" styleName="c-btn--3" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export const query = graphql`
  query {
    prismic {
      allLive_pages {
        edges {
          node {
            first_section_title
            first_section_subtitle
            second_section_content
            quest_title
            quest_description
            triva_title
            triva_description
            iride_title
            iride_description
            latent_pay_title
            latent_pay_description
            quantum_title
            quantum_description
            fourth_section_title
          }
        }
      }
    }
    imageFirstSection: file(relativePath: { eq: "photos/live/image-main.png" }) {
      childImageSharp {
        fluid(maxWidth: 816, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    imageThirdSectionQuest: file(relativePath: { eq: "photos/live/image Quest.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    #    imageThirdSectionTriva: file(relativePath: { eq: "photos/live/image Triva.png" }) {
    #      childImageSharp {
    #        fluid(maxWidth: 1000, quality: 90) {
    #          ...GatsbyImageSharpFluid_noBase64
    #        }
    #      }
    #    }
    #    imageThirdSectioniRide: file(relativePath: { eq: "photos/live/image iRide.png" }) {
    #      childImageSharp {
    #        fluid(maxWidth: 1000, quality: 90) {
    #          ...GatsbyImageSharpFluid_noBase64
    #        }
    #      }
    #    }
    #    imageThirdSectionLatentPay: file(relativePath: { eq: "photos/live/image Latent Pay.png" }) {
    #      childImageSharp {
    #        fluid(maxWidth: 1000, quality: 90) {
    #          ...GatsbyImageSharpFluid_noBase64
    #        }
    #      }
    #    }
    imageThirdSectionQuantum: file(relativePath: { eq: "photos/live/image Quantum.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default LivePage;
